import React from 'react';

function PrivacyPolicy() {
    const zero = <>
        <h3>Загальні положення</h3>
        <p>Ця Угода регулює використання та пошук інформації користувачем на вебсайті http://vitapol.info в мережі Інтернет.</p>
        <p>Отримавши доступ до матеріалів вебсайту, вважається, що користувач приєднується до Угоди і погоджується на юридично обов’язкове виконання її умов.</p>
        <p>Якщо користувач відмовляється виконувати умови Угоди, то він зобов’язаний не користуватися вебсайтом http://vitapol.info надалі.</p>
        <p>Вебсайт http://vitapol.info – спеціалізований інтернет-проєкт для фахівців галузі охорони  здоров’я України. Інформація, опублікована на вебсайті, призначена тільки для медичних працівників, які мають вищу або середню спеціальну освіту.</p>
    </>;

    const one = <>
        <h3>1. Терміни та поняття</h3>
        <ul>
            <li>1.1. ТОВ «ВІТ-А-ПОЛ» – власник вебсайту, адміністрація вебсайту. </li>
            <li>1.2. Вебсайт (портал) – інтернет-проєкт, що надає доступ до інформації та сприяє її пошуку.</li>
            <li>1.3. Користувач – будь-яка особа, котра отримує доступ до інформації та пошуку на вебсайті http://vitapol.info.</li>
            <li>1.4. Послуга – будь-яка послуга та функції, пов’язані з нею, що надаються користувачу безкоштовно.</li>
            <li>1.5. Матеріали вебсайту – це розміщені на його сторінках тексти, динамічні зображення, графічні рішення, відеофільми тощо.</li>
        </ul>
    </>

    const two = <>
        <h3>2. Предмет Угоди</h3>
        <p>Ця Угода (далі – Угода) визначає взаємовідносини з особою, користувачем інформації (надалі – Користувач), та Адміністрацією вебсайту (далі – «Адміністрація вебсайту») щодо використання матеріалів і сервісів вебсайту http://vitapol.info (далі – вебсайт).</p>
    </>

    const three = <>
        <h3>3. Права та обов’язки Користувача</h3>
        <ul>
            <li>3.1. Користувач має право використовувати матеріали та сервіси вебсайту з метою отримання інформації зі сфери охорони здоров’я.</li>
            <li>
                <span>3.2. Користувач зобов’язаний:</span>
                <ul className="markedList">
                    <li>перед відвідуванням вебсайту підтвердити свою фахову приналежність до працівників галузі охорони здоров’я України у спосіб, визначений Адміністрацією вебсайту;</li>
                    <li>використовувати матеріали та сервіси вебсайту відповідно до норм чинного законодавства України, положень цієї Угоди винятково в ознайомчих цілях;</li>
                    <li>не розміщувати на вебсайті повідомлень насильницького, образливого характеру, за які передбачена адміністративна (кримінальна) відповідальність;</li>
                    <li>не публікувати комерційну інформацію;</li>
                    <li> не втручатися у будь-який спосіб у роботу (функціонування) вебсайту.</li>
                </ul>
            </li>
        </ul>
    </>

    const four = <>
        <h3>4. Права та обов’язки Адміністрації вебсайту</h3>
        <ul>
            <li>
                <span>4.1. Адміністрація вебсайту має право:</span>
                <ul className="markedList">
                    <li> в односторонньому порядку змінювати умови Угоди та правила користування вебсайтом;</li>
                    <li> проводити систематичний моніторинг дотримання умов Угоди Користувачем і вживати необхідних заходів у разі виявлення таких порушень.</li>
                </ul>
            </li>
            <li>
                <span>4.2. Адміністрація вебсайту зобов’язана:</span>
                <ul className="markedList">
                    <li>здійснювати перевірку контенту вебсайту, дописів Користувача щодо відповідності положенням та умовам Угоди;</li>
                    <li>у разі порушення положень та умов Угоди Користувачем – видалити його обліковий запис, а попередньо видалити неправомірно розміщену ним інформацію.</li>
                </ul>
            </li>
        </ul>
    </>

    const five = <>
        <h3>5. Умови використання матеріалів та сервісів вебсайту</h3>
        <p>Будь-яке ознайомлення, завантаження чи використання в інший спосіб матеріалів та сервісів вебсайту означає використання вебсайту.</p>
        <p>Матеріали вебсайту призначені для ознайомлення медичних працівників. Вони не можуть слугувати настановами для діагностики та лікування без участі спеціалістів-медиків.</p>
        <p>При використанні вебсайту є обов’язковим наявність прямого гіперпосилання на адресу матеріалу на вебсайті.</p>
        <p>Тиражування матеріалів можливе тільки з дозволу Адміністрації вебсайту або після укладання ліцензійних договорів від правовласників.</p>
        <p>Використання матеріалів та сервісів вебсайту з будь-якою іншою метою вважається неправомірним.</p>
    </>

    const six = <>
        <h3>6. Відповідальність Адміністрації вебсайту та Користувача. Обмеження відповідальності</h3>
        <p>На вебсайті публікуються матеріали медичної тематики.</p>
        <p>Авторство матеріалів належить спеціалістам охорони здоров’я.</p>
        <p>Матеріали є ознайомлювальними для медичних працівників з вищою та середньою спеціальною освітою і не можуть бути остаточним керівництвом для самостійної діагностики та лікування.</p>
        <p>Зазначені на вебсайті методи діагностики, лікування, рецептура, лікарські засоби можуть застосовуватися лише після консультації з лікарем і під його наглядом.</p>
        <p>Адміністрація вебсайту не несе відповідальності за можливі негативні наслідки чи збитки, нанесені здоров’ю, що виникли в наслідок самостійного лікування та використання опублікованої на вебсайті інформації</p>
        <p>Претензії щодо відповідальності за негативні наслідки самостійного лікування не можуть бути пред’явлені Адміністрації вебсайту і стати предметом судового розгляду.</p>
        <p>На вебсайті можуть розміщуватися реклама та рекламна інформація, за зміст яких відповідає рекламодавець.</p>
        <p>Користувач приймає положення, що Адміністрація вебсайту не має будь-яких зобов’язань щодо такої реклами, яка є рекламою третіх осіб.</p>
        <p>Користувач згоден з тим, що використовує матеріали вебсайту на свій ризик і приймає до відома те, що опубліковані матеріали можуть містити помилки.</p>
        <p>Адміністрація не гарантує безперебійної роботи вебсайту та його сервісів.</p>
    </>

    const final = <>
        <h3>Заключні положення</h3>
        <p>Користувач ознайомлений з Угодою і приймає безумовно усі її положення та умови.</p>
        <p>Ця Угода укладена на невизначений термін і набирає чинності з моменту використання матеріалів вебсайту.</p>
    </>

    return <div className="PrivacyPolicy">
        {zero}
        {one}
        {two}
        {three}
        {four}
        {five}
        {six}
        {final}
    </div>
}

export default PrivacyPolicy;