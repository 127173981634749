import React from 'react';

function AboutServices() {
    const readyArticlesPart = <>
        <h3>Підготовка статей до друку в спеціалізованих медичних журналах</h3>
        <p>Наш професійний колектив журналістів, перекладачів, літературних і технічних редакторів пропонує послуги з фахової підготовки до друку статей.</p>
        <ul className="stardartList">
            <li>Редагування</li>
            <li>Вичитування</li>
            <li>Макетування (оформлення статей)</li>
            <li>Переклад</li>
        </ul>
        <p>
            Якість послуг перекладів текстів забезпечується співробітництвом із професійними дипломованими перекладачами, які працюють з текстами загальної та спеціалізованої медичної тематики.
        </p>
    </>

    const advertisingPart = <>
        <h3>Реклама в спеціалізованих медичних журналах</h3>
        <p>
            Фахівці Видавничо-інформаційної групи «ВІТ-А-ПОЛ»:
        </p>
        <ul className="stardartList">
            <li>підберуть для вас потрібні видання з урахуванням цільової аудиторії, бюджету;</li>
            <li>розроблять для вас оригінальний макет рекламного (іміджевого, інформаційного) блоку.</li>
        </ul>
    </>

    const reprintArticles = <>
        <h3>Репринти статей</h3>
        <p>У нас ви можете замовити репринт будь-якої статті, опублікованої в усіх журналах Видавничо-інформаційної групи «ВІТ-А-ПОЛ», а також отримати потрібну статтю в зручному вигляді (pdf, docx або будь-який інший формат за бажанням). Крім того, у нас можна придбати права на передрук будь-якої статті, яка вас цікавить, опублікованої в наших журналах раніше.</p>
        <p>Ми забезпечуємо транспортну доставку виготовленої продукції за адресою замовника.</p>
    </>

    const adressPart = <>
        <h3>Адресна розсилка</h3>
        <p>
            Видавничо-інформаційна група «ВІТ-А-ПОЛ» надає послуги Direct Mail (адресна розсилка, кур'єрська доставка), спеціалізується на наданні комплексних послуг у сфері Direct Marketing.
        </p>
    </>

    const eJournalsPart = <>
        <h3>Випуск електронних журналів</h3>
        <p>Видавничо-інформаційна група «ВІТ-А-ПОЛ» пропонує послуги з випуску електронних версій журналів та організації передплати на них.</p>
        <p>Електронні версії журналів – це:</p>
        <table className="serviceTable">
            <tbody>
                <tr>
                    <td><span>вигідно</span></td>
                    <td>економніші умови передплати, наявність версії в мережі Інтернет</td>
                </tr>
                <tr>
                    <td><span>масштабно</span></td>
                    <td>велика інформаційна база, що вміщує архів номерів журналу за попередні роки, а також поточні номери</td>
                </tr>
                <tr>
                    <td><span>оперативно</span></td>
                    <td>оперативний пошук інформації за тематикою, ключовими словами, назвою, номером видання, автором</td>
                </tr>
                <tr>
                    <td><span>зручно</span></td>
                    <td>вся актуальна інформація завжди під рукою — у вашому комп'ютері</td>
                </tr>
                <tr>
                    <td><span>гарантовано</span></td>
                    <td>свіжі номери видання отримуються в день виходу друкованої версії видання зручним способом (Інтернет або e-mail)</td>
                </tr>
            </tbody>
        </table>
        {/* <ul className="uniqueList">
            економніші умови передплати, наявність версії в мережі Інтернет
            велика інформаційна база, що вміщує архів номерів журналу за попередні роки, а також поточні номери
            оперативний пошук інформації за тематикою, ключовими словами, назвою, номером видання, автором
            вся актуальна інформація завжди під рукою — у Вашому комп'ютері
            свіжі номери видання отримуються в день виходу друкованої версії видання зручним способом (Інтернет або e-mail
        </ul> */}
    </>

    const banerAdvertisingPart = <>
        <h3>Банерна реклама на сайті</h3>
        <p>
            Ми пропонуємо послуги з розміщення банерної реклами на сайті, а також засоби моніторингу показів.
        </p>
    </>

    const infoMessagePart = <>
        <h3>Інформаційні повідомлення на сайті</h3>
        <p>Ще один вид реклами - розміщення текстових інформаційних повідомлень (текст обсягом до 2 сторінок А4) на сайтах медичних журналів.</p>
        <p>Приклад такого повідомлення – новина, яка відмічена як «Актуальна публікація», що висвітлює подію в галузі охорони здоров'я і містить згадку про компанію, лікарські засоби, висновки спеціалістів. </p>
        <p>Сайт дає можливість опублікувати інформацію раніше виходу друкованої версії номера журналу, а також залишити її у постійному доступі.</p>
    </>

    const statistikPart = <>
        <h3>Проведення анкетувань, дискусій та опитувань спеціалістів в галузі охорони здоров'я через сайт</h3>
        <p>Інтерактивні онлайн сервіси дають можливість:</p>
        <ul className="stardartList">
            <li>збирати інформацію маркетингового характеру;</li>
            <li>висвітлювати проведення опитування і його результати у ЗМІ;</li>
            <li>отримувати у вигляді зворотного зв'язку від учасників опитування матеріал для подальших публікацій, заходів тощо.</li>
        </ul>
        <p>
            Опитування можуть містити як закриті запитання (коли відвідувач просто вказує один або кілька заданих варіантів відповіді), так і відкриті, коли відвідувачу дається можливість висловити свою думку.
        </p>
        <p>
            За бажанням, після закінчення збору інформації на сайті можна опублікувати результати у відкритому доступі.
        </p>
    </>

    return <div className="aboutServices">
        {readyArticlesPart}
        <hr className="hr2"/>
        {advertisingPart}
        <hr className="hr2"/>
        {reprintArticles}
        <hr className="hr2"/>
        {adressPart}
        <hr className="hr2"/>
        {eJournalsPart}
        <hr className="hr2"/>
        {banerAdvertisingPart}
        <hr className="hr2"/>
        {infoMessagePart}
        <hr className="hr2"/>
        {statistikPart}
    </div>
}

export default AboutServices;