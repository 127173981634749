const text = {
    ukr: {
        alsoTitle: 'Також по темі',
        goToStartPage: "Повернутися на початок сторінки",
        Section: "Гастроентерологія",
        ReferenceTitleName: "Література",
        Resume: "Резюме",
        Keywords: "Ключові слова",
        newsTitle: 'Новини',
        MagazinesTitle: 'Журнали',
        AuthorsLinkText: 'Автори >>',
        articlesTitle: "Статті, лекції, огляди, інформація",
        articlesSpecialion: 'Більше статей >>',
        recomendationViewmore: 'Більше тем >>',
        recomendationBlockTitle: "клінічні настанови, протоколи, рекомендації",
        activitiesTitle: "наукові заходи",
        searchPlaceHolderText: "Пошук",
        videoListTitle: "Відео",
        videoPageReturn: "Повернутися до відео",
        authorsMainTitle: "Автори",
        justAuthor: "Автор",
        paginationPrevLabel: "Назад",
        paginationNextLabel: "Вперед",
        stateCategoriesTitle: 'Статті за темою',
        videoPreviewTitle: 'Відео',
        organizationPreviewTitle: 'Професійні медичні асоціації',
        viewMoreVideos: "Більше відео >>",
        datePublic: 'Дата публікації',
        anchorNewsLinkText: 'Повернутися до новин',
        mainNewsTitle: 'Актуальна публікація',
        activitiesPreviewText: 'Більше заходів >>',
        organizationPreviewText: 'Більше організацій >>',
        notFound: '404 - Сторінку не знайдено',
        map: 'Контакти',
        aboutCompany: 'Про нас',
        services: 'Послуги',
        projects: 'Проєкти',
        doctorPractic: 'Клінічні настанови',
        address: 'Контакти',
        articleT: 'Статті',
        rightsProtected: 'ТОВ «ВІТ-А-ПОЛ». Усі права захищені',
        footerTitleText: "Адреса і контакти",
        footerAdress: "вул. Академіка Єфремова, 19а, оф. 3, м. Київ, 03179",
        footerTown: "м. Київ, 03179",
        footerTelBuilding: "Тел.: +38 044 298 00 60, +38 044 298 00 61",
        footerEmailBuilding: "Електронна пошта: vitapol3@gmail.com",
        footerTitleTextSec: "Завідувач секретаріату науково-практичних журналів",
        secretar: "Ольга Миколаївна Берник",
        footerTelSec: "Тел.: +38 044 298 00 61",
        footerEmailSec: "Електронна пошта: vitapol3@gmail.com",
        aboutSite: "Про вебсайт",
        agreement: "Угода користувача інформації вебсайту",
        permission: "Дозвіл на використання матеріалів сайту",
        medicOrg: "Професійні медичні організації",
        footerSpecial: "Спеціалізований вебсайт для фахівців галузі охорони здоров'я України",
        privacyPolicyTitle: "Угода користувача інформації вебсайту http://vitapol.info",
        aboutSiteVitapol: 'Про вебсайт http://vitapol.info',
        arrowLeft: "<",
        arrowRight: ">",
        searchResultTitle: "Результати пошуку",
        searchFieldPlaceHolder: "Пошук статей",
        searchResultBreadcrumb: "Пошук статей",
        foundResultText: "Знайдено результатів"
    },
    // rus: {
    //     alsoTitle: 'Также по теме',
    //     goToStartPage: 'Вернутся на начало страницы',
    //     Section: 'Гастроэнтерология',
    //     ReferenceTitleName: 'Литература',
    //     Resume: 'Резюме',
    //     Keywords: 'Ключевые слова',
    //     newsTitle: 'Новости',
    //     MagazinesTitle: 'Журналы',
    //     AuthorsLinkText: 'Авторы >>',
    //     articlesTitle: 'Статьи, лекции, информация',
    //     articlesSpecialion: 'Больше статей >>',
    //     recomendationViewmore: 'Больше тем >>',
    //     recomendationBlockTitle: 'клинические руководства, протоколы, рекомендации',
    //     activitiesTitle: 'научные мероприятия',
    //     searchPlaceHolderText: 'Поиск',
    //     videoListTitle: 'Видео',
    //     videoPageReturn: 'Вернутся к видео',
    //     authorsMainTitle: 'Авторы',
    //     justAuthor: 'Автор',
    //     paginationPrevLabel: 'Назад',
    //     paginationNextLabel: 'Вперед',
    //     stateCategoriesTitle: 'статьи по теме',
    //     videoPreviewTitle: 'Видео',
    //     organizationPreviewTitle: 'Профессиональные медицинские ассоциации',
    //     viewMoreVideos: 'Больше видео >>',
    //     datePublic: 'Дата публикации',
    //     anchorNewsLinkText: 'Вернутся к новостям',
    //     mainNewsTitle: 'Актуальная публикация',
    //     activitiesPreviewText: 'Больше мероприятий >>',
    //     organizationPreviewText:'Больше организаций >>',
    //     notFound: '404 - Страница',
    //     map: 'Контакты',
    //     aboutCompany: 'О нас',
    //     services: 'Услуги',
    //     projects: 'Проекты',
    //     doctorPractic: 'Клинические руководства',
    //     address: 'Контакты',
    //     articleT: 'Статьи',
    //     rightsProtected: 'ООО «ВИТ-А-ПОЛ». Все права защищены',
    //     footerTitleText: "Адрес и контакты",
    //     footerAdress: "ул. Академика Ефремова, 19а, оф. 3, г. Киев, 03179",
    //     footerTown: "г. Киев, 03179",
    //     footerTelBuilding: "Тел.: +38 044 298 00 60, +38 044 298 00 61",
    //     footerEmailBuilding: "Электронная почта: vitapol3@gmail.com, journals@vitapol.com.ua",
    //     footerTitleTextSec: "Заведующая секретариатом научно-практических журналов",
    //     secretar: "Ольга Николаевна Берник",
    //     footerTelSec: "Тел.: +38 044 298 00 61",
    //     footerEmailSec: "Электронная почта: vitapol3@gmail.com, journals@vitapol.com.ua",
    //     aboutSite: "О вебсайте",
    //     agreement: "Соглашение об использовании информации на сайте",
    //     permission: "Разрешение на использование материалов сайта",
    //     medicOrg: "Профессиональные медицинские ассоциации",
    //     footerSpecial: "Специализированный вебсайт для специалистов в области здравоохранения Украины",
    //     privacyPolicyTitle: 'Угода пользователя информации вебсайта "http://vitapol.info"',
    //     aboutSiteVitapol: 'О вебсайте  http://vitapol.info',
    //     arrowLeft: "<",
    //     arrowRight: ">",
    //     searchResultTitle: "Результаты поиска",
    //     searchFieldPlaceHolder: "Поиск статей",
    //     searchResultBreadcrumb: "Поиск статей",
    //     foundResultText: "Найдено результатов"
    // }
}

function getI18Text(name, language) {
    if (name && language) {
        return text[language] ? text[language][name] : text['ukr'][name];
    } else {
        return '';
    }
}

export default getI18Text;