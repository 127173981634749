import React, {useCallback, useState} from 'react';
import Journal from './Journal';

const projectsDataArr = [
    {
        name: "Сучасна гастроентерологія",
        fullname: "Найвідоміший національний гастроентерологічний часопис",
        img: "/img/about/journals/gastro.png",
        special: "гастроентерологія, внутрішні хвороби, клінічна фармакологія, загальна практика — сімейна медицина",
        mainEditor: "Галина Дмитрівна Фадєєнко, доктор медичних наук, професор, член-кореспондент НАМН України, директор Державної установи «Національний інститут терапії імені Л. Т. Малої Національної академії медичних наук України»",
        shefEditor: "",
        yearFoundation: 2000,
        ISSN: "1727-5725 (Print), ISSN 2521-649X (Online)",
        registrationMON: "Журнал включено до Переліку наукових фахових видань України з медичних наук, категорія 'А'. Додаток №5 до наказу Міністерства освіти і науки України № 582 від 24 квітня 2024 р. У виданні можуть публікуватися результати дисертаційних робіт на здобуття наукових ступенів доктора наук, кандидата наук та ступеня доктора філософії",
        languages: "українська, англійська",
        periodicity: "4 рази на рік",
        circulation: "(PRINT + ONLINE): 2400 прим.",
        format: "А4",
        amount: "120–136 сторінок",
        printing: "високоякісний офсетний друк на крейдованому папері, кольорові обкладинка та внутрішні вставки, чорно-білий текстовий блок"
    },
    {
        name: "Український терапевтичний журнал",
        fullname: "Класичне видання українських терапевтів",
        img: "/img/about/journals/terapevt.png",
        special: "внутрішні хвороби, кардіологія, пульмонологія, гастроентерологія, нефрологія, клінічна фармакологія",
        mainEditor: "Галина Дмитрівна Фадєєнко, доктор медичних наук, професор, член-кореспондент НАМН України, директор Державної установи «Національний інститут терапії імені Л. Т. Малої Національної академії медичних наук України»",
        shefEditor: "",
        yearFoundation: 1998,
        ISSN: "1605-7295 (Print), 2522-1175 (Online)",
        registrationMON: "Додаток 5 до наказу Міністерства освіти і науки України 24.09.2020 № 1188 (журнал внесено до Переліку наукових фахових видань України, в яких можуть публікуватися результати дисертаційних робіт на здобуття наукових ступенів доктора наук, кандидата наук та ступеня доктора філософії. Категорія 'Б')",
        languages: "українська, англійська",
        periodicity: "4 рази на рік",
        circulation: "(PRINT + ONLINE): 2600 прим.",
        format: "А4",
        amount: "120–136 сторінок",
        printing: "високоякісний офсетний друк на крейдованому папері, кольорові обкладинка та внутрішні вставки, чорно-білий текстовий блок"
    },
    {
        name: "Український неврологічний журнал",
        fullname: "Загальнонаціональний неврологічний часопис",
        img: "/img/about/journals/nevro.png",
        special: "неврологія ",
        mainEditor: "Володимир Степанович Мельник, доктор медичних наук, професор, Національний медичний університет імені О. О. Богомольця",
        shefEditor: "",
        yearFoundation: 2006,
        ISSN: "1998-4235 (Print), 2522-1183 (Online)",
        registrationMON: "Наказ МОН України від 17.03.2020 № 409 (журнал внесено до Переліку наукових фахових видань України, в яких можуть публікуватися результати дисертаційних робіт на здобуття наукових ступенів доктора наук, кандидата наук та ступеня доктора філософії. Категорія 'Б')",
        languages: "українська, англійська",
        periodicity: "4 рази на рік",
        circulation: "(PRINT + ONLINE): 2000 прим.",
        format: "А4",
        amount: "120–136 сторінок",
        printing: "високоякісний офсетний друк на крейдованому папері, кольорові обкладинка та внутрішні вставки, чорно-білий текстовий блок"
    },
    {
        name: "Український журнал дитячої ендокринології",
        fullname: "Загальнонаціональне спеціалізоване видання для ендокринологів, педіатрів, сімейних лікарів",
        img: "/img/about/journals/dit_endo.jpg",
        special: "дитяча ендокринологія ",
        mainEditor: "Наталія Борисівна Зелінська, доктор медичних наук, професор, президент Асоціації дитячих ендокринологів України",
        shefEditor: "",
        yearFoundation: 2010,
        ISSN: "2304-005X (Print), 2523-4277 (Online)",
        registrationMON: "Додаток 4 до наказу Міністерства освіти і науки України від 02.07.2020 № 886 (журнал внесено до Переліку наукових фахових видань України, в яких можуть публікуватися результати дисертаційних робіт на здобуття наукових ступенів доктора наук, кандидата наук та ступеня доктора філософії. Категорія 'Б')",
        languages: "українська, англійська.",
        periodicity: "4 рази на рік",
        circulation: "(PRINT + ONLINE): 1000 прим.",
        format: "А4",
        amount: "108–120 сторінок",
        printing: "високоякісний офсетний друк на крейдованому папері, кольорові обкладинка та внутрішні вставки, чорно-білий текстовий блок"
    },
    {
        name: "Туберкульоз, легеневі хвороби, ВІЛ-інфекція",
        fullname: "Всеукраїнський науково-практичний журнал для фтизіатрів, пульмонологів, терапевтів, інфекціоністів і торакальних хірургів",
        img: "/img/about/journals/turbo.png",
        special: "фтизіатрія, пульмонологія, інфектологія, інфекційні хвороби",
        mainEditor: "Василь Іванович Петренко, доктор медичних наук, професор, Національний медичний університет імені О. О. Богомольця ",
        shefEditor: "",
        yearFoundation: 2010,
        ISSN: "2220-5071 (Print), 2522-1094 (Online)",
        registrationMON: "Журнал включено до Переліку наукових фахових видань України з медичних наук, категорія 'А'. Додаток 10 до наказу МОН України від 26.06.2024, № 920. У виданні можуть публікуватися результати дисертаційних робіт на здобуття наукових ступенів доктора наук, кандидата наук та ступеня доктора філософії",
        languages: "українська, англійська",
        periodicity: "4 рази на рік",
        circulation: "(PRINT + ONLINE): 2160 прим.",
        format: "А4",
        amount: "120–136 сторінок",
        printing: "високоякісний офсетний друк на крейдованому папері, кольорові обкладинка та внутрішні вставки, чорно-білий текстовий блок"
    },
    // {
    //     name: "Серце і судини",
    //     fullname: "Авторитетний журнал кардіологів та серцево-судинних хірургів України",
    //     img: "/img/about/journals/heart.png",
    //     special: "кардіологія, серцево-судинна хірургія",
    //     mainEditor: "Катерина Миколаївна Амосова, член-кореспондент НАМН України, доктор медичних наук, професор, Національний медичний університет імені О. О. Богомольця",
    //     shefEditor: "Володимир Григорович Мішалов, доктор медичних наук, професор, заслужений лікар України",
    //     yearFoundation: "2003",
    //     ISSN: "1727-5717 (Print), 2521-6910 (Online)",
    //     registrationMON: "Додаток 9 до наказу Міністерства освіти і науки України від 09.03.2016 р. № 241. ",
    //     languages: "українська, англійська",
    //     periodicity: "4 рази на рік",
    //     circulation: "річний – 8000 примірників, одного випуску – 2000 примірників",
    //     format: "А4",
    //     amount: "120–136 сторінок",
    //     printing: "високоякісний офсетний друк на крейдованому папері, кольорові обкладинка та внутрішні вставки, чорно-білий текстовий блок"
    // },
    // {
    //     name: "Хірургія України",
    //     fullname: "Провідний журнал українських хірургів з високою науковою репутацією і рівнем довіри",
    //     img: "/img/about/journals/surgery.png",
    //     special: "хірургія",
    //     mainEditor: "Володимир Григорович Мішалов, доктор медичних наук, професор",
    //     shefEditor: "",
    //     yearFoundation: 2001,
    //     ISSN: "1727-5717 (Print), 2521-6910 (Online).",
    //     registrationMON: "Додаток 9 до наказу Міністерства освіти і науки України від 09.03.2016 р. № 241.",
    //     languages: "українська, англійська",
    //     periodicity: "4 рази на рік",
    //     circulation: "річний – 8000 примірників, одного випуску – 2000 примірників",
    //     format: "А4",
    //     amount: "120–136 сторінок",
    //     printing: "високоякісний офсетний друк на крейдованому папері, кольорові обкладинка та внутрішні вставки, чорно-білий текстовий блок"
    // },
    {
        name: "Клінічна ендокринологія та ендокринна хірургія",
        fullname: "Загальнонаціональне спеціалізоване видання для ендокринологів",
        img: "/img/about/journals/endo_surgery.jpg",
        special: "хірургія/ендокринна хірургія, ендокринна гінекологія/репродуктологія, патофізіологія, патоморфологія, дитяча ендокринологія",
        mainEditor: "Олександр Андрійович Товкай, директор Українського науково-практичного центру ендокринної хірургії, трансплантації ендокринних органів і тканин МОЗ України, доктор медичних наук, професор, Заслужений лікар України, лікар-хірург вищої категорії, лікар-онколог",
        shefEditor: "",
        yearFoundation: 2010,
        ISSN: "1818-1384 (Print), 2519-2582 (Online)",
        registrationMON: "Наказ МОН України від 17.03.2020 № 409 (журнал внесено до Переліку наукових фахових видань України, в яких можуть публікуватися результати дисертаційних робіт на здобуття наукових ступенів доктора наук, кандидата наук та ступеня доктора філософії. Категорія 'Б')",
        languages: "українська, англійська",
        periodicity: "4 рази на рік",
        circulation: "(PRINT + ONLINE): 2200 прим.",
        format: "А4",
        amount: "108–120 сторінок",
        printing: "високоякісний офсетний друк на крейдованому папері, кольорові обкладинка та внутрішні вставки, чорно-білий текстовий блок"
    },
    {
        name: "Український журнал дерматології, венерології, косметології",
        fullname: "Передовий журнал українських дерматовенерологів і косметологів",
        img: "/img/about/journals/dermat.png",
        special: "дерматологія, венерологія, косметологія ",
        mainEditor: "Віктор Іванович Степаненко, доктор медичних наук, професор, член-кореспондент НАМН України, Президент Української асоціації лікарів-дерматовенерологів і косметологів, завідувач кафедри дерматології та венерології з курсом косметології Національного медичного університету імені О. О. Богомольця",
        shefEditor: "",
        yearFoundation: 2001,
        ISSN: "1727-5741 (Print), 2522-1035 (Online)",
        registrationMON: "Додаток 4 до Наказу Міністерства освіти і науки України від 02.07.2020 № 886 (журнал внесено до Переліку наукових фахових видань України, в яких можуть публікуватися результати дисертаційних робіт на здобуття наукових ступенів доктора наук, кандидата наук та ступеня доктора філософії. Категорія 'Б')",
        languages: "українська, англійська",
        periodicity: "4 рази на рік",
        circulation: "(PRINT + ONLINE): 2400 прим.",
        format: "А4",
        amount: "120–136 сторінок",
        printing: "високоякісний офсетний друк на крейдованому папері, кольорові обкладинка та внутрішні вставки, чорно-білий текстовий блок"
    },
    {
        name: "General Surgery",
        fullname: "Спеціалізоване науково-практичне рецензоване видання для хірургічної спільноти України з міжнародною участю",
        img: "/img/about/journals/General_Surgery 1_2021_cover.jpg",
        special: "хірургія, онкологія, дитяча хірургія, трансплантологія та штучні органи, анестезіологія та інтенсивна терапія, імунологія",
        mainEditor: "Сусак Ярослав Михайлович, доктор медичних наук, професор, завідувач кафедри хірургії з курсом невідкладної та судинної хірургії Національного медичного університету імені О. О. Богомольця",
        yearFoundation: 2021,
        ISSN: "2786-5584 (Print), 2786-5592 (Online)",
        registrationMON: "Додаток 3 до Наказу Міністерства освіти і науки України від 23.12.2022 №1166 (журнал внесено до Переліку наукових фахових видань України, в яких можуть публікуватися результати дисертаційних робіт на здобуття наукових ступенів доктора наук, кандидата наук та ступеня доктора філософії. Категорія 'Б')",
        languages: "англійська, абстракти – українською",
        periodicity: "4 рази на рік",
        circulation: "(PRINT + ONLINE): 2000 прим.",
        format: "А4",
    }
]

function AboutProjects() {
    const [journalIndex, setJournalIndex] = useState(0);
    const setIndexHandler = useCallback((event) => setJournalIndex(event.target.getAttribute('index')), []);

    const journalsPart = <>
        <p>Спеціалізовані видання Видавничо-інформаційної групи «ВІТ-А-ПОЛ» створюються на сучасній науковій базі провідних медичних центрів країни.</p>
        <p>Головними редакторами журналів є відомі українські вчені й авторитетні громадські діячі.</p>
        <p>За високий науковий рівень, актуальність тематики усі журнали мають статус наукових фахових видань, затверджений Департаментом атестації кадрів МОН України.</p>
        <p>Публікації у передових загальнонаціональних виданнях мають безпосередній вплив на формування науково-освітнього, фахового рівня українських медиків.</p>
        <h5>Інформація про журнали</h5>
        <ul className="journalsList">
            {projectsDataArr.map((data, index) => <li key={data.name}>
                <span onClick={setIndexHandler} index={index}>{data.name}</span>
            </li>)}
        </ul>
    </>

    return <div className="aboutProjects">
        {journalsPart}
        <Journal {...projectsDataArr[journalIndex]}/>

        <h6 className="allJournalsHeader">Дивитися інший журнал</h6>
        <ul className="allJournals">
            {projectsDataArr.map((data, index) => <li key={data.name}>
                <span onClick={setIndexHandler} index={index}>{data.name}</span>
            </li>)}
        </ul>
    </div>
}

export default AboutProjects;